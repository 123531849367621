import React from 'react'
import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'

// interface Props {
//    loadPercent: number;
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        position: 'absolute',
        top: '80%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

    },
  }),
);


export default function LoadingComponent()
{
    const classes = useStyles();
    return <div className={classes.root}><CircularProgress /></div>
    
}