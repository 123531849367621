import * as React from 'react';
import '../css/navbar.css';
//import { AuthContext } from '../AzureADB2C/auth-context'
import {AppBar, Box, Toolbar, Typography, Link} from "@material-ui/core"

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authconfig';
import '../css/site.css';
import { UserContext } from '../UserContext';
import { eUserType } from '../Enums';
import { DigitalStaticURL, NPLTimeText } from '../Constants';

// function ListItemLink(props: any) {
//   return <ListItem button component="a" {...props} />;
// }

function NavMenu(props: any)  {

      const { instance, accounts } = useMsal();
      
      // const account = useAccount(accounts[0] || {}) || undefined;
      const { user } = React.useContext(UserContext);
    
      // const[IsAdmin, setIsAdmin] = React.useState(false);
      let loginLinks;      

      if(user.userType === eUserType.User || user.userType === eUserType.Admin || user.userType ===  eUserType.NPLAdmin) {
        console.log("inside is admin in navmenu");
        loginLinks = <ul className="nav_links">
          <li><Link href="/">Service Status Reports</Link></li>
          {user.userType !== eUserType.Admin ? <li><Link href="/Reports">Fee Report</Link></li> : null}
          <li><Link href="/Logs">Event Log</Link></li>
          
          <li><button onClick={() => instance.logoutRedirect({postLogoutRedirectUri: "/"})}>Sign out</button></li>
        </ul>
      }
      else
      {
        loginLinks = <ul className="nav_links">
          <li><Link href="/">Service Status Reports</Link></li>
          
            {/* <li><a href="/Reports">Fee Report</a></li> */}
          {/* <li onClick={() => instance.onEditProfile()}>Edit Profile</li> */}
          <li><button onClick={() => instance.logoutRedirect({postLogoutRedirectUri: "/"})}>Sign out</button></li>
        </ul>
      }

     


      
    const NavLinks = (
      <div className="nav_links">
      
        <AuthenticatedTemplate>
          {loginLinks}
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <ul className="nav_links">
            <li><a href="/">Home</a></li>
            <li><button onClick={() => instance.loginRedirect(loginRequest)}>Sign in</button></li>
          </ul>
        </UnauthenticatedTemplate>
      </div>
    );

    return (
      <div>
      <AppBar position="static" style={{background: '#002F6C', height:56}}>
          <Toolbar>
               
              <Typography variant="h6" className="maintitle">
                <img src={`${DigitalStaticURL}/npl-header-logo.png`} alt="NPL Logo" />  
                <span className="caption"><NPLTimeText /></span>
              </Typography>

              {user.userType === eUserType.User || user.userType === eUserType.Admin || user.userType ===  eUserType.NPLAdmin ?
                <Box sx={{ marginLeft: '10px' }}>
                  <Typography>Welcome {accounts[0]?.name}</Typography>
                </Box>
              : null}

              {NavLinks}
      
          </Toolbar>
      </AppBar>

    
        {/* sx={{
          top: 56,
          left: 0,  
          width: 1980,
          height: 201,
          background: '#E5F5FC 0% 0% no-repeat padding-box';
          opacity: 1;
          // bgcolor: 'primary.dark',
          // '&:hover': {
          //   backgroundColor: 'primary.main',
          //   opacity: 1,
          // },
        }}
      /> */}
      
      </div>

    );
  
}

//NavMenu.contextType = AuthContext;

export default NavMenu;




