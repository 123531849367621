import * as React from 'react'
import { Box, Container, Grid } from "@material-ui/core";
import '../css/site.css'
import { DigitalStaticURL } from '../Constants';

export function Footer() {
    return  <Box className='footer'>
    <Container fixed>
             <Grid container>
                <Grid item xs={2}><div className="footerImage"><img src={`${DigitalStaticURL}/npl-footer-logo.png`} alt="NPL Logo" /></div></Grid>
                <Grid item xs={2}>
                    <div className="footerHeader">Support</div> 
                    <div className="footerText"> 
                        <div><a href="mailto:npltimetechnical@npl.co.uk">Email us</a></div>
                    </div>
                </Grid>
                <Grid item xs={2}>
                <div className="footerHeader">Useful Links</div>
                <div className="footerText">
                    <div><a href="https://www.npl.co.uk/quality" rel="noreferrer" target="_blank">Quality</a></div>
                    <div><a href="https://www.npl.co.uk/health-safety-and-environment" rel="noreferrer" target="_blank">HSE</a></div>
                    <div><a href="https://www.npl.co.uk/contact" rel="noreferrer" target="_blank">Contact</a></div>
                    <div><a href="https://www.npl.co.uk/terms-conditions" rel="noreferrer" target="_blank">Terms</a></div>
                    <div><a href="https://www.npl.co.uk/anti-slavery-and-ethics" rel="noreferrer" target="_blank"> Anti-Slavery Policy</a></div>
                    <div><a href="https://www.npl.co.uk/accessibility" rel="noreferrer" target="_blank">Accessibility</a></div>
                    <div><a href="https://www.npl.co.uk/privacy-notice" rel="noreferrer" target="_blank">Privacy Policy</a></div>
                    </div>
                </Grid>
                <Grid item xs={2}>
                   
                </Grid>
                <Grid item xs={4}> 
                        <div className="footerHeader">© National Physical Laboratory {new Date().getFullYear()}</div>
                         <div className="footerText">National Physical Laboratory
                         <br/>Hampton Road
                         <br/>Teddington
                         <br/>Middlesex
                         <br/>TW11 0LW
                         <br/>Tel: 020 8977 3222</div>
                   
                </Grid>
               
            </Grid> 
    </Container>
    </Box>  
    
}
