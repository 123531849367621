import { useAccount, useMsal } from "@azure/msal-react";
import { Box, Button, Container, FormGroup, Grid, TextField, Typography } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { object, date, number, string } from "yup";
import agent from "../api/agent";
import { protectedResources } from "../authconfig";
import { NPLTimeText } from "../Constants";
import { eUserType } from "../Enums";
import { Distribution, Location, LocationEndPoint } from "../models/filter";
import { PTPDeviceLog } from "../models/PTPDeviceLog";
import { UserContext } from "../UserContext";

export default function CreateDeviceLog(props: any) {
    const { instance, accounts } = useMsal();
    const { search } = useLocation();
    const account = useAccount(accounts[0] || {}) || undefined;
    const[distributors, setDistributors] = useState<Distribution[]>([]);
    const[locations, setLocations] = useState<Location[]>([]);
    const[endpoints, setEndpoints] = useState<LocationEndPoint[]>([]);
    const[selectedDistributor, setSelectedDistributor] = useState<number>(0);
    const[selectedLocation, setSelectedLocation] = useState<number>(0);
    const[selectedEndPoint, setSelectedEndPoint] = useState<number>(0);
    const[dataSaved, setDataSaved] = useState(false);
    const { user } = useContext(UserContext);
    const navigate = useHistory();

    if (user.userType === eUserType.AccessUser) {
        navigate.push('/');
    }

    let deviceId: string | null = new URLSearchParams(search).get('id');
    let initialValues: PTPDeviceLog = {
        id: 0,
        userId: '',
        timestamp: '',
        endPoint: 0,
        endpointName: '',
        locationId: 0,
        location: '',
        distributor: 0,
        dataPointType: 0,
        comments: '',
        logNumber: '',
        distributorRef: '',
        distributorName: "",
    }
    const[deviceLog, setDeviceLog] = useState<PTPDeviceLog>(initialValues);

    const deviceLogValidationSchema = object().shape({
        timestamp: date().required(),
        distributor: number().required(),
        comments: string().required(),
        logNumber: string().required(),
        distributorRef: string().required(),
    });

    useEffect(() => {
        document.title = ((deviceId === null || deviceId === "0") ? 'Create' : 'Edit') + " Event Log - NPL Time";
    }, [deviceId]);

    useEffect(() => {
        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
        }).then((response) => {
            agent.report.getLocations(selectedDistributor, response.accessToken)
            .then((res) => (setLocations(res)));
        });
    }, [account, instance, selectedDistributor]);

    useEffect(() => {
        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
        }).then((response) => {
            agent.report.getLocationEndpoints(selectedDistributor, selectedLocation, response.accessToken)
            .then((res) => (setEndpoints(res)));
        });
    }, [account, instance, selectedDistributor, selectedLocation]);

    useEffect(() => {
        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
        }).then((response) => {
            if(deviceId != null) {
                initialValues.id = parseInt(deviceId);
            }

            agent.report.getDistributions(response.accessToken)
            .then((res) => (setDistributors(res)));

            if(deviceId !== null && deviceId !== "0") {
                agent.deviceLog.GetAllLogs(0, 0, 0, response.accessToken)
                .then((deviceLogModels:PTPDeviceLog[]) => {
                    let deviceLogModel = deviceLogModels.find(x => x.id === parseInt(deviceId || "0"));
                    if (deviceLogModel !== undefined) {
                        console.log("DLM", deviceLogModel);
                        setSelectedDistributor(deviceLogModel.distributor);
                        setSelectedLocation(deviceLogModel.locationId);
                        setSelectedEndPoint(deviceLogModel.endPoint);
                        setDeviceLog(deviceLogModel);
                    } else {
                        console.log(`DLM was missing for ${deviceId}.`);
                    }
                });
            } else {
                setDeviceLog(initialValues);
            }

            setDeviceLog(initialValues);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[account, instance, deviceId]);

    const onSubmit = (values: PTPDeviceLog, formikHelpers: any) => {
        console.log(values);
        console.log(formikHelpers);
        formikHelpers.setSubmitting(false)

        instance.acquireTokenSilent({
            scopes: protectedResources.apiNPLTime.scopes,
            account: account
            }).then((response) => {
                if(account) {
                    values.userId = account?.localAccountId;
                }

                values.distributor = selectedDistributor;
                values.location    = selectedLocation.toString();
                values.locationId  = selectedLocation;
                values.endPoint    = selectedEndPoint;

                agent.deviceLog.create(values.userId, values, response.accessToken)
                    .then((res) => {
                        console.log(res);

                        alert("Data Saved successfully");

                        setDataSaved(true);
                        return (<Redirect to="/Logs" />);

                    });
                }

        );
    };

    if (dataSaved) {
        return (<Redirect to="/Logs" />);
    }

    return(
        <div>
            <Box className='layout'>
                <Container fixed>
                    <Grid container className="titlePadding">
                        <Grid item xs={12} className="title"><NPLTimeText /></Grid>
                    </Grid>
                </Container>
            </Box>
            <Container className="middleContainer">
            <Typography component="h1" style={{ fontSize: '1.75em', fontWeight: 'bold' }}>Create Event Log</Typography>
                <Typography>All fields are required.</Typography>
                <Formik
                    initialValues={deviceLog}
                    validationSchema={deviceLogValidationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                >
                    {({values, errors, touched, isSubmitting}) => {
                        let readonly = (user.userType === eUserType.NPLAdmin || user.userType === eUserType.User) ? false : true;
                        return (
                            <Form>
                                <Box marginBottom={3}>
                                    <FormGroup>
                                        <Field
                                            name="distributor"
                                            as={TextField}
                                            select
                                            label="Distributor"
                                            value={selectedDistributor}
                                            onChange={(event:any) => {
                                                setSelectedDistributor(event.target.value);
                                                setSelectedLocation(0);
                                                setSelectedEndPoint(0);
                                            }}
                                            disabled={readonly}
                                            error={errors.distributor && touched.distributor}
                                            helperText={errors.distributor && touched.distributor ? errors.distributor : null}
                                        >
                                            <option value={0}>All</option>
                                            {distributors && distributors.map((e:Distribution, key:number) => {
                                                return <option key={key} value={e.id}>{e.name}</option>;
                                            })}
                                        </Field>

                                        <Field
                                            name="locationId"
                                            as={TextField}
                                            select
                                            label="Location"
                                            value={selectedLocation}
                                            onChange={(event:any) => {
                                                setSelectedLocation(event.target.value);
                                                setSelectedEndPoint(0);
                                            }}
                                            disabled={readonly || selectedDistributor === 0}
                                            error={errors.locationId && touched.locationId}
                                            helperText={errors.locationId && touched.locationId ? errors.locationId : null}
                                        >
                                            <option value={0}>All</option>
                                            {locations && locations.map((e:Location, key:number) => {
                                                return <option key={key} value={e.id}>{e.name}</option>;
                                            })}
                                        </Field>

                                        <Field
                                            name="endPoint"
                                            as={TextField}
                                            select
                                            label="Endpoint"
                                            value={selectedEndPoint}
                                            onChange={(event:any) => {
                                                setSelectedEndPoint(event.target.value);
                                            }}
                                            disabled={readonly || selectedLocation === 0}
                                            error={errors.endPoint && touched.endPoint}
                                            helperText={errors.endPoint && touched.endPoint ? errors.endPoint : null}
                                        >
                                            <option value={0}>All</option>
                                            {endpoints && endpoints.map((e:LocationEndPoint, key:number) => {
                                                return <option key={key} value={e.id}>{e.endPoint}</option>;
                                            })}
                                        </Field>

                                        <Field
                                            name="timestamp"
                                            as={TextField}
                                            label="Occurrance Time"
                                            type="datetime-local"
                                            disabled={readonly}
                                            error={errors.timestamp && touched.timestamp}
                                            helperText={errors.timestamp && touched.timestamp ? errors.timestamp : null}
                                        />

                                        <Field
                                            name="comments"
                                            as={TextField}
                                            label="Comment(s)"
                                            type="text"
                                            disabled={readonly}
                                            error={errors.comments && touched.comments}
                                            helperText={errors.comments && touched.comments ? errors.comments : null}
                                        />

                                        <Field
                                            name="logNumber"
                                            as={TextField}
                                            label="Log Number"
                                            type="text"
                                            disabled={readonly}
                                            error={errors.logNumber && touched.logNumber}
                                            helperText={errors.logNumber && touched.logNumber ? errors.logNumber : null}
                                        />

                                        <Field
                                            name="distributorRef"
                                            as={TextField}
                                            label="Distributor Reference"
                                            type="text"
                                            disabled={readonly}
                                            error={errors.distributorRef && touched.distributorRef}
                                            helperText={errors.distributorRef && touched.distributorRef ? errors.distributorRef : null}
                                        />
                                    </FormGroup>
                                </Box>

                                <Box marginBottom={3}>
                                    <FormGroup>
                                        <Button
                                            type="submit"
                                            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall submitbtn toppad"
                                            disabled={readonly}
                                        >
                                            Submit
                                        </Button>
                                    </FormGroup>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Container>
        </div>
    );
}