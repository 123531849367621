import axios, { AxiosResponse } from 'axios';
import { NPLTimeUser } from '../models/NPLTimeUser';
import { DataFilter, Distribution, feeReportFilter, Location, LocationEndPoint }  from '../models/filter';
import { FeeReport } from '../models/FeeReport';
import {DuplicateReport} from '../models/DuplicateReport';
import { PTPChartData, TimeChartData } from '../models/ChartData';
import { PTPDeviceLog } from '../models/PTPDeviceLog';

axios.defaults.baseURL =  process.env.REACT_APP_API_URL; //'https://localhost:44327/api';

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    getWithToken: <T>(url: string, accessToken: string) => axios.get<T>(url, {headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    }}).then(responseBody), 
    getFileWithToken: (url: string, accessToken: string) => axios({
        url: url , //'https://localhost:44327/api/patientsessions/0/documents/32',
        method: 'GET',
        responseType: 'blob', // important
        headers: { 'Authorization': `Bearer ${accessToken}`},
    }).then(responseBody), 

    post: (url: string, body: {}) => axios.post(url, body).then(responseBody), 
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody), 

    postWithToken: (url: string, body: {}, accessToken: string) => axios.post(url, body, {headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    }}).then(responseBody), 
    putWithToken: (url: string, body: {}, accessToken: string) => axios.put(url, body, {headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    }}).then(responseBody), 

    del: (url: string) => axios.delete(url).then(responseBody), 
    delWithToken: (url: string, body: {}, accessToken: string) => axios.delete(url, {headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    }}).then(responseBody),
}

const time = {
    GetTimeData: (id: number, filter: DataFilter, accessToken: string) => requests.postWithToken(`/NPLTime/GetFiles/${id}`, filter, accessToken),
    downloadPDF:(year: number, month: number, accessToken: string) => (requests.getFileWithToken(`/Admin/GetFile/${year}/${month}`, accessToken )),
    GetHubAvailabilityData:(accessToken: string) => (requests.getWithToken<TimeChartData[]>('/NPLTime/GetHubAvailabilityData', accessToken)),
    GetPTPData:(year: number, month: number, endpoint: number, location: number, distributorId: number, fileId: number, userId: string, accessToken: string) => (
        requests.getWithToken<PTPChartData[]>(`/NPLTime/GetPTPDataPoints?year=${year}&month=${month}&endpoint=${endpoint}&fileId=${fileId}&userId=${userId}&location=${location}&distributorId=${distributorId}`, accessToken)
    ), 
}

const deviceLog = {
    GetAllLogs: (distributor :number = 0, location :number = 0, endpoint :number = 0, accessToken: string) => requests.getWithToken<PTPDeviceLog[]>(
        `/PTPDeviceLog?DistributorId=${distributor}&LocationId=${location}&EndPointId=${endpoint}`, accessToken
    ),
    create: (id: string, data:PTPDeviceLog, accessToken: string) => (requests.postWithToken(`/PTPDeviceLog/${id}`, data, accessToken)),
    delete: (id: number, accessToken:string) => (requests.delWithToken(`/PTPDeviceLog/${id}`, {}, accessToken)),
}

const user = {
    details: (id: string, accessToken: string) => requests.getWithToken<NPLTimeUser>(`/User/${id}`, accessToken),
    Update: (id: string, values: NPLTimeUser, accessToken: string) => requests.putWithToken(`/User/${id}`, values, accessToken),
    all: (accessToken: string) => requests.getWithToken<NPLTimeUser[]>(`/User/`, accessToken),
}
const report = {
    createfee: (id: string, data:FeeReport, accessToken: string) => (requests.postWithToken(`/Admin/${id}`, data, accessToken)),
    getReport:(id: string, filters: feeReportFilter, accessToken: string) => (requests.postWithToken(`/Admin/GetReport`, filters, accessToken)),
    data:(id:number, accessToken: string) => (requests.getWithToken<FeeReport>(`/Admin/${id}`, accessToken)),
    getDistributions: (accessToken:string) => (requests.getWithToken<Distribution[]>(`Admin/GetDistributors`, accessToken)),
    getLocations:(distributionId:number, accessToken:string) => (requests.getWithToken<Location[]>(`Admin/GetLocations/${distributionId}`, accessToken)),
    getLocationEndpoints:(distributionId:number, locationId:number, accessToken:string) => (requests.getWithToken<LocationEndPoint[]>(`Admin/GetLocationEndpoints/${distributionId}/${locationId}`, accessToken)),
    duplicateFeeReport: (id: number, accessToken: string) => (requests.postWithToken(`/Admin/DuplicateFeeReport/${id}`, {}, accessToken)),
    duplicateQuarter: (duplicateFeeReport: DuplicateReport, accessToken: string) => (requests.postWithToken(`Admin/DuplicateFeeReportToCurrentQuarter`, duplicateFeeReport, accessToken)),
    deleteReport: (id: number, accessToken:string) => (requests.delWithToken(`/Admin/${id}`, {}, accessToken)),
    submitReports: (filters: feeReportFilter, accessToken: string) => (requests.postWithToken(`/Admin/SubmitFeeReport`, filters, accessToken)),
}



const agent = {
    time,
    deviceLog,
    user,
    report
}

export default agent;

