export enum eFeeReportStatus {
    New = 0,
    Submitted = 99
}

export enum eUserType {
    User = 1,
    Admin = 2,
    AccessUser = 3,
    NPLAdmin = 999
}
